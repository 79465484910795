
import { Component, Vue } from 'vue-property-decorator';


@Component({
  components: {
    HelloWorld:() =>  import('@/components/cmpViewer3D.vue'),
  },
})
export default class Home extends Vue {}
